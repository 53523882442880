import {
  container,
  main,
  mainRaised,
  title,
  whiteColor,
  grayColor,
  section,

  sectionDarkMarketing,

  sectionDark,
  sectionDescription,
  sectionDarkContact

} from "./nextjs-material-kit-pro.js";

import "./typography.css";

const componentsStyle = {
  main,
  mainRaised,
  parallax: {
    height: "70vh",
    overflow: "hidden",
    backgroundPosition: "center top"
  },
containerLeak: {
    padding: "20px 80px 0px 180px",
    "@media screen and (max-width:500px)": {
        padding: "0px 0px 0px 0px",
     },

      "@media(min-width: 768px) and (max-width: 1024px)": {
            padding: "60px 0px 0px 0px"
        }
  },

  description2: {
    color: "black"
  },

  button : {
      "@media screen and (max-width:500px)": {
        textAlign: "center",
        paddingLeft: "20px"
      }
  },

  sectionDarkContact,

   imageContainer: {
     padding: "60px 80px 0px 80px",
     marginTop: "40px", 
     textAlign: "center",
    "@media screen and (max-width:500px)": {
        padding: "0px 60px 0px 60px",
     },
  },
  image: {
    width:"100%",
    height:"300px",
    boxShadow: "0 8px 6px -6px black",
    display: "block",
    borderRadius: "3px",
    position: "relative",
    top: "40px",

    "@media(min-width: 768px) and (max-width: 1024px)": {
          width:"100%",
          height:"310px",
          position: "relative",
          top: "40px",
      
    },
    "@media(max-width: 500px)": {
      maxWidth: "100%",
      maxHeight: "210px",
      display: "flex",
      marginLeft: "30px",
    }
  },
    
  container: {
    ...container,
    zIndex: "2",
    color: "#FFFFFF",
    position: "relative"
  },
  brand: {
    color: whiteColor,
    textAlign: "center",
    "& h1": {
      fontSize: "4.2rem",
      fontWeight: "600",
      display: "inline-block",
      position: "relative"
    },
    "& h3": {
      fontSize: "1.313rem",
      maxWidth: "500px",
      margin: "10px auto 0"
    }
  },
  title: {
    ...title,
    color: `"black" + "  !important"`,
    marginTop: "0px",
    textTransform: "uppercase",
    fontWeight: "500",
    fontFamily: "poppins",
    fontSize: "44px",
    "@media screen and (max-width:500px)": {
      fontSize: "30px"
    }
  },
  
   title3: {
    ...title,
    color: `"black" + "  !important"`,
    marginTop: "0px",
    textTransform: "uppercase",
    fontWeight: "500",
    fontFamily: "poppins",
    paddingRight: "40px",
    fontSize: "44px",
    "@media screen and (max-width:500px)": {
      fontSize: "30px",
       paddingLeft: "20px",
       paddingTop: "0px",
       textAlign: "center"
    }
  },
    title4: {
    ...title,
    color: `"black" + "  !important"`,
    marginTop: "0px",
    textTransform: "uppercase",
    fontWeight: "bold",
    fontFamily: "poppins2",
    paddingRight: "40px",
    fontSize: "30px",
    paddingTop: "80px",
   
    "@media screen and (max-width:500px)": {
      fontSize: "25px",
      paddingLeft: "20px",
      paddingTop: "60px",
      textAlign: "center"
    }
  },

   title5: {
    ...title,
   color: `"black" + "  !important"`,
    marginTop: "0px",
    textTransform: "uppercase",
    fontWeight: "bold",
    fontFamily: "poppins2",
    paddingRight: "40px",
    fontSize: "30px",
    paddingTop: "20px",
   
    "@media screen and (max-width:500px)": {
      fontSize: "25px",
      paddingLeft: "20px",
      paddingTop: "20px",
      textAlign: "center"
    }
  },

    title6: {
    ...title,
    color: `white`,
    marginTop: "0px",
    textTransform: "uppercase",
    fontWeight: "500",
    fontFamily: "poppins",
    fontSize: "44px",
    "@media screen and (max-width:500px)": {
      fontSize: "30px"
    }
  },


    title2: {
    ...title,
    color: `"black" + "  !important"`,
    marginTop: "0px",
    textTransform: "uppercase",
    fontWeight: "500",
    fontFamily: "poppins",
    fontSize: "35px",
    "@media screen and (max-width:500px)": {
      fontSize: "26px"
    }
  },
  link: {
    textDecoration: "none"
  },
  textCenter: {
    textAlign: "center"
  },

  section: {
    ...section,
    padding: "60px 0px 80px 0px"
  },

  section2: {
    ...section,
    padding: "60px 0px 80px 120px",
    borderRadius: "0px",
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
     "@media screen and (max-width: 500px)": {
       padding: "60px 0px 60px 0px"
     }
  },

  section3: {
    ...section,
    padding: "60px 0px 80px 0px",
    borderRadius: "0px",
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
     "@media screen and (max-width: 500px)": {
       padding: "60px 0px 60px 0px"
     },
        "@media(min-width: 768px) and (max-width: 1024px)": {
            padding: "60px 0px 60px 0px"
        }
  },

  
  sectionGray: {
    background: grayColor[14]
  },

  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right"
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative"
  },

  sectionDarkMarketing,

  sectionDark,

  sectionDescription,

  paragraph: {
    color: "#212121",
    fontSize: "16px",
    fontFamily: "poppins2",
    fontWeight: "300",
    lineHeight: "32px",
    textAlign: "left",
      "@media screen and (max-width:500px)": {
      
        paddingLeft: "30px",
        paddingRight: "0px"
      }
  },

  paragraph2: {
    color: "white",
    fontSize: "20px",
    fontFamily: "poppins",
    fontWeight: "500",
    lineHeight: "32px",
      "@media screen and (max-width:500px)": {
        textAlign: "center",
        fontSize: "16px"
      }
  },

};

export default componentsStyle;