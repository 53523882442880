/*eslint-disable*/
import React from "react";
import { Link } from "gatsby";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import styles from "../../jss/headerLinksStyle.js";
import "../../jss/headerlink.css";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();

  return (
    <List
      style={{ position: "relative", left: "20px" }}
      className={classes.header}
    >
      <ListItem className={classes.listItem} alignItems="flex-start">
        <div className="dropdown">
          <button className="dropbtn">
            <b style={{ fontSize: "16px" }}>RESIDENTIAL</b>{" "}
          </button>
          <div className="dropdown-content">
            <Link
              href="https://www.goproplumbing.ca/plumbing-services"
              to="/plumbing-services"
            >
              <div className={classes.dropdownLinkRight}>
                <b>FIXTURE UPGRADES</b>
              </div>
            </Link>
            <Link
              href="https://www.goproplumbing.ca/licensed-plumber"
              to="/licensed-plumber"
            >
              <div className={classes.dropdownLinkRight}>
                <b>NEW CONSTRUCTION</b>
              </div>
            </Link>
            <Link
              href="https://www.goproplumbing.ca/24-hour-plumber"
              to="/24-hour-plumber"
            >
              <div className={classes.dropdownLinkRight}>
                <b>TROUBLESHOOTING</b>
              </div>
            </Link>
            <Link
              href="https://www.goproplumbing.ca/after-hour-plumber"
              to="/after-hour-plumber"
            >
              <div className={classes.dropdownLinkRight}>
                <b>DRAIN CLEANING</b>
              </div>
            </Link>
            <Link
              href="https://www.goproplumbing.ca/backwater-valve-plumbers-toronto"
              to="/backwater-valve-plumbers-toronto"
            >
              <div className={classes.dropdownLinkRight}>
                <b>BACKWATER VALVE</b>
              </div>
            </Link>
            <Link
              href="https://www.goproplumbing.ca/kitec-removal"
              to="/kitec-removal"
            >
              <div className={classes.dropdownLinkRight}>
                <b>KITEC REMOVAL</b>
              </div>
            </Link>
            <Link
              href="https://www.goproplumbing.ca/watermain-replacement"
              to="/watermain-replacement"
            >
              <div className={classes.dropdownLinkRight}>
                <b>WATERMAIN REPLACEMENT</b>
              </div>
            </Link>
          </div>
        </div>
      </ListItem>

      <ListItem className={classes.listItem} alignItems="flex-start">
        <div className="dropdown">
          <button className="dropbtn">
            <b style={{ fontSize: "16px" }}>COMMERCIAL</b>{" "}
          </button>
          <div className="dropdown-content">
            <Link
              href="https://www.goproplumbing.ca/commercial-construction"
              to="/commercial-construction"
            >
              <div className={classes.dropdownLinkRight}>
                <b>NEW CONSTRUCTION</b>
              </div>
            </Link>
            <Link
              href="https://www.goproplumbing.ca/fixture-upgrades-commercial"
              to="/fixture-upgrades-commercial"
            >
              <div className={classes.dropdownLinkRight}>
                <b>FIXTURE UPGRADE</b>
              </div>
            </Link>
            <Link
              href="https://www.goproplumbing.ca/drain-cleaning-inspection-commercial"
              to="/drain-cleaning-inspection-commercial"
            >
              <div className={classes.dropdownLinkRight}>
                <b>DRAIN CLEANING</b>
              </div>
            </Link>
            <Link
              href="https://www.goproplumbing.ca/commercial-plumbing-troubleshooting"
              to="/commercial-plumbing-troubleshooting"
            >
              <div className={classes.dropdownLinkRight}>
                <b>TROUBLESHOOTING</b>
              </div>
            </Link>
            <Link
              href="https://www.goproplumbing.ca/watermain-replacement-commercial"
              to="/watermain-replacement-commercial"
            >
              <div className={classes.dropdownLinkRight}>
                <b>WATERMAIN REPLACEMENT</b>
              </div>
            </Link>
            <Link
              href="https://www.goproplumbing.ca/kitec-removal-commercial"
              to="/kitec-removal-commercial"
            >
              <div className={classes.dropdownLinkRight}>
                <b>KITEC REMOVAL</b>
              </div>
            </Link>
            <Link
              href="https://www.goproplumbing.ca/riser-replacement-commercial"
              to="/riser-replacement-commercial"
            >
              <div className={classes.dropdownLinkRight}>
                <b>RISER REPLACEMENT</b>
              </div>
            </Link>
          </div>
        </div>
      </ListItem>

      <ListItem className={classes.listItem}>
        <Link
          href="https://www.goproplumbing.ca/leak-detection-phyn"
          to="/leak-detection-phyn"
        >
          <div className={classes.dropdownLink}>
            <b>LEAK DETECTION</b>
          </div>
        </Link>
      </ListItem>

      <ListItem className={classes.listItem}>
        <Link
          href="https://www.goproplumbing.ca/emergency-plumber"
          to="/emergency-plumber"
        >
          <div className={classes.dropdownLink}>
            <b>EMERGENCY PLUMBER</b>
          </div>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link href="https://www.goproplumbing.ca/contact-us" to="/contact-us">
          <div className={classes.dropdownLink}>
            <b>CONTACT US</b>
          </div>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link href="https://www.goproplumbing.ca/about-us" to="/about-us">
          <div className={classes.dropdownLink}>
            <b>ABOUT US</b>
          </div>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <div>
          <a href="tel:647-370-9868" className={classes.dropdownLink}>
            <b>647-370-9868</b>
          </a>
        </div>
      </ListItem>
    </List>
  );
}
