import {
    title,
    container
} from "./nextjs-material-kit-pro.js";

import "./typography.css";
const productStyle = {
    section: {
        padding: "60px 0",
        textAlign: "center"
    },
    container: {
        ...container
    },
    title: {
        ...title,
        fontSize: "38px",
        marginBottom: "1rem",
        marginTop: "30px",
        minHeight: "45px",
        textDecoration: "none",
        fontWeight: "500",
        fontFamily: "poppins",
        "@media screen and (max-width:500px)": {
            fontSize: "28px",
            textAlign: "center"
        },
        "@media(min-width: 768px) and (max-width: 1024px)": {
            fontSize: "30px",
             textAlign: "center"
        }
    },

    aboutMore: {
        "@media(min-width: 372px) and (max-width: 736px)": {
            padding: "0px"
            
        }
    },

    button: {
        backgroundColor: "#d19132", color: "white" , marginTop: "1px",
        height:"50px",
        "@media (min-width: 1281px)": {
            marginLeft: "40px"
        },
        "&:hover": {
            backgroundColor: "#d0a057"
        }
    },
    bannerTitle: {
        ...title,
        marginBottom: "1rem",

        textDecoration: "none",
        "@media screen and (max-width:500px)": {
               fontSize: "15px"
        },
        "@media(min-width: 768px) and (max-width: 1024px)": {
            fontSize: "30px"
        }
    },
    description: {
        color: "#4c4c4c",
        padding: "15px"
    },
    image: {
        width: "100%",
        padding: "60px",
        height: "auto",
        maxWidth: "600px",

    },

    imageDesktop: {
        boxShadow: "0 8px 6px -6px black",
        borderRadius: "3px",
        padding: "20px",
        "@media screen and (max-width:500px)": {
            order: 1
        },
        "@media(min-width: 768px) and (max-width: 1024px)": {
            order: 1
        },
        "@media screen and (min-width:1200px)": {
            padding: "20px 20px 0px 80px", 
            marginTop: "40px" 
        }
    },
    containerOrder: {
        "@media screen and (max-width:500px)": {
            order: 2
        },
        "@media(min-width: 768px) and (max-width: 1280px)": {
            order: 2
        }

    },

    containerInfo: {
        padding: "80px 0px 80px 0px",
        "@media screen and (max-width:500px)": {
            padding: "0px",
        },
        "@media(min-width: 768px) and (max-width: 1280px)": {
            padding: "0px",
        }

    },
  


};

export default productStyle;