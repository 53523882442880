import React from "react";
// nodejs library that concatenates classes

import {
  LogoJsonLd,
  LocalBusinessJsonLd,
  GatsbySeo,
} from "gatsby-plugin-next-seo";

export default function Parallax(props) {
  return (
    <div>
      <GatsbySeo
        openGraph={{
          type: "website",
          locale: "en_IE",
          description:
            "GoPro Plumbing is fully licensed to conduct all general plumbing in Toronto, Oakville, Brampton and Greater Toronto Area (GTA). Our plumbers in Toronto can help you with any plumbing repair.",
          url: "https://goproplumbing.ca/",
          site_name: "GoPro Plumbing",
          images: [
            {
              url:
                "https://storage.googleapis.com/archatech/GoProPlumbing/Screen%20Shot%202020-12-09%20at%202.02.12%20PM.png",
              width: 800,
              height: 450,
              alt: "Plumber in Toronto",
            },
          ],
        }}
      />
      <LogoJsonLd
        logo="https://storage.googleapis.com/archatech/GoProPlumbing/Logo.png"
        url="https://goproplumbing.ca/"
      />
      <LocalBusinessJsonLd
        type="HomeAndConstructionBusiness"
        id="https://goproplumbing.ca/"
        name="GoPro Plumbing"
        description="GoPro Plumbing is fully licensed to conduct all general plumbing in Toronto, Oakville, Brampton and Greater Toronto Area (GTA). Our plumbers in Toronto can help you with any plumbing repair."
        url="https://goproplumbing.ca/"
        telephone="+1647-370-9868"
        address={{
          streetAddress: "3660 Midland Avenue East",
          addressLocality: "Toronto",
          addressRegion: "ON",
          postalCode: "M1V0B8",
          addressCountry: "CA",
        }}
        priceRange="$$$"
        geo={{
          latitude: "43.813538",
          longitude: "-79.2927859",
        }}
        images={[
          "https://storage.googleapis.com/archatech/GoProPlumbing/IMG_0005.JPG",
          "https://storage.googleapis.com/archatech/GoProPlumbing/IMG_0012%202.JPG",
        ]}
        sameAs={[
          "https://www.facebook.com/GoProPlumbingAndMechincalinc/",
          "https://www.instagram.com/goproplumbing_/",
          "https://www.youtube.com/channel/UCxWqVttuxTbnKfB1KfrMLVQ",
        ]}
        openingHours={[
          {
            opens: "08:00",
            closes: "17:00",
            dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
            validFrom: "2019-12-23",
            validThrough: "2024-04-02",
          },
          {
            opens: "12:00",
            closes: "4:00",
            dayOfWeek: "Saturday",
            validFrom: "2019-12-23",
            validThrough: "2024-04-02",
          },
        ]}
      />
    </div>
  );
}
