import {
  defaultFont
} from "./nextjs-material-kit.js";

import tooltip from "./tooltipsStyle.js";

const headerLinksStyle = theme => ({
  list: {
    ...defaultFont,
    fontSize: "16px",
    margin: 0,
    paddingLeft: "0",
    listStyle: "none",
    paddingTop: "0",
    paddingBottom: "0",
    color: "inherit",
  },
  listItem: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    fontSize: "16px",
    width: "auto",
    margin: "0",
    padding: "5px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      float: "center",
      textAlign: "left",
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "left",
      float: "center",
      color: "inherit",
      position: "relative",
      display: "flex",
      fontSize: "16px",
      width: "autoo",
      margin: "0px",
      padding: "0px",
    }
  },

  dropbtn: {
    backgroundColor: "transparent",
    color: "white",
    padding: "16px",
    fontSize: "16px",
    border: "none",
    "&:hover,&:dropdownContent": {
      display: "block"
    }
  },

  dropdown: {
    position: "relative",
    display: "inline-block",
   "&:hover": {
      display: "block"

    },
    "@media (min-width: 320px) and (max-width: 480px)": {
        float: "left",
        color: "inherit",
        textAlign: "left",
        position: "relative",
        display: "block",
        width: "auto",
        margin: "0",
      
        padding: "1px 5px"
    },
    "@media (min-width: 768px) and (max-width: 1024px)": {
        float: "left",
        color: "inherit",
        textAlign: "left",
        position: "relative",
        display: "block",
        width: "auto",
        margin: "0",
       
        padding: "1px 5px"
    }
  },


  dropdownContent: {
    
    display: "none",
    position: "absolute",
    backgroundColor: "#24282e",
    textAlign: "left",
    minWidth: "200px",
    boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
    zIndex: "1",

    "&a:hover": {
      backgroundColor: "#d0a057",
      color: "white",
      textAlign: "left"
    },
    "&a": {
       backgroundColor: "white",
       textAlign: "left",
       textDecoration: "none",
       display: "block"

    }
  },




  dropdownLinkOne: {
    color: "inherit",
    "&:hover": {
      color: "#d0a057",
      textDecoration: "#d0a057",
      display: "block",
    },
    cursor: "pointer",
  },
  listItemCollapse: {

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "&:after": {
        width: "calc(100% - 30px)",
        content: '""',
        display: "block",
        height: "1px",
        marginLeft: "15px",
        backgroundColor: "#e5e5e5"
      }
    },

    float: "left",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    fontWeight: "700",
    fontSize: "14px",

    "&,&:hover,&:focus": {
      color: "inherit",
      textDecoration: "none",
      display: "block",
      padding: "10px 20px"
    }
  },

  listItemText: {
    padding: "0 !important"
  },
  navLink: {
    color: "inherit",
    position: "relative",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    "&:hover,&:focus": {
      color: "inherit",
      background: "rgba(200, 200, 200, 0.2)"
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)",
      marginLeft: "15px",
      marginBottom: "8px",
      marginTop: "8px",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start"
      }
    },


  },

  registerNavLink: {
    [theme.breakpoints.down("md")]: {
      top: "0",
      margin: "5px 15px"
    },
    top: "3px",
    position: "relative",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex"
  },
  navLinkActive: {
    color: "inherit",
    backgroundColor: "rgba(255, 255, 255, 0.1)"
  },
  icons: {
    width: "20px",
    height: "20px",
    marginRight: "3px"
  },
  socialIcons: {
    position: "relative",
    fontSize: "20px !important",
    marginRight: "4px"
  },
  dropdownLinkRight: {
    "&,&:hover,&:focus": {
      color: "white",
      textDecoration: "none",
      display: "block",
      padding: "10px 10px",
      textAlign: "left"
    },
    "&:hover": {
      color: "white"
    }
  },
    dropdownLink: {
    "&,&:hover,&:focus": {
      color: "white",
      textDecoration: "none",
      display: "block",
      padding: "10px 10px",
      textAlign: "left",
       "&:hover": {
        color: "#0099CC"
      }
    },
  },
  ...tooltip,
  marginRight5: {
    marginRight: "5px"
  }
});

export default headerLinksStyle;


