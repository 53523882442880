/*eslint-disable*/
import React, { useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui icons

import { Link } from "gatsby";

// sections for this page
import Grid from "@material-ui/core/Grid";
import Phone from "@material-ui/icons/Phone";
import Email from "@material-ui/icons/Email";
import Location from "@material-ui/icons/LocationOn";

import Facebook from "../../assets/facebook.svg";

import Instagram from "../../assets/instagram.svg";

import Houzz from "../../assets/houzz.svg";
import Youtube from "../../assets/youtube.svg";
import Trusted from "../../assets/home.svg";
import styles from "../../jss/footerStyle.js";

const useStyles = makeStyles({
  ...styles,
  linkStyle: {
    display: "inlineBlock",
    padding: "12px",
    height: "48px",
  },
  footerLink: {
    color: "white",
    display: "inlineBlock",
    height: "48px",
    "&:hover": {
      color: "#0099CC",
    },
  },

  header: {
    color: "#0099CC",
    fontWeight: "bold",
  },

  icons: {
    color: "white",
    marginRight: "5px",

    "&:hover": {
      color: "#0099CC",
    },
  },
});

export default function Footer(props) {
  const classes = useStyles();

  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const [showPro, setPro] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [showYoutube, setShowYoutube] = useState(false);
  const [showInstagram, setShowInstagram] = useState(false);
  const [showHouzz, setShowHouzz] = useState(false);
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div>
          <Grid container justify="center">
            <Grid item xs={10} sm={12} md={2} lg={2}>
              <h4 className={classes.header}>CONTACT</h4>
              <ul className={classes.linksVertical}>
                <li>
                  <a href="tel:647-370-9868" className={classes.icons}>
                    {" "}
                    <Phone className={classes.icons} />
                    CALL
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:info@goproplumbing.ca"
                    className={classes.icons}
                  >
                    <Email className={classes.icons} />
                    EMAIL
                  </a>
                </li>
                <li>
                  <a
                    className={classes.icons}
                    href="https://www.google.com/maps/place/Go+Pro+Plumbing+%26+Mechanical+Inc/@43.7641044,-79.3191238,15z/data=!4m2!3m1!1s0x0:0x20c86b0693c7f4b5?sa=X&ved=2ahUKEwj66OqB-IztAhUYWs0KHcxiBR0Q_BIwCnoECBMQBQ"
                  >
                    <Location className={classes.icons} />
                    ADDRESS
                  </a>
                </li>
              </ul>
            </Grid>

            <Grid item xs={10} sm={12} md={3} lg={2}>
              <h4 className={classes.header}>RESIDENTIAL</h4>
              <ul className={classes.linksVertical}>
                <li>
                  <Link
                    href="https://www.goproplumbing.ca/plumbing-services"
                    to="/plumbing-services"
                  >
                    Fixture Upgrades
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.goproplumbing.ca/licensed-plumber"
                    to="/licensed-plumber"
                  >
                    New Construction{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.goproplumbing.ca/24-hour-plumber"
                    to="/24-hour-plumber"
                  >
                    Troubleshooting
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.goproplumbing.ca/after-hour-plumber"
                    to="/after-hour-plumber"
                  >
                    Drain Cleaning
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.goproplumbing.ca/backwater-valve-plumbers-toronto"
                    to="/backwater-valve-plumbers-toronto"
                  >
                    Backwater Valve
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.goproplumbing.ca/kitec-removal"
                    to="/kitec-removal"
                  >
                    Kitec Removal
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.goproplumbing.ca/watermain-replacement"
                    to="/watermain-replacement"
                  >
                    Watermain Replacement
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid item xs={10} sm={12} md={3} lg={2}>
              <h4 className={classes.header}>COMMERCIAL</h4>
              <ul className={classes.linksVertical}>
                <li>
                  <Link
                    href="https://www.goproplumbing.ca/commercial-construction"
                    to="/commercial-construction"
                  >
                    New Construction
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.goproplumbing.ca/fixture-upgrades-commercial"
                    to="/fixture-upgrades-commercial"
                  >
                    Fixture Upgrade
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.goproplumbing.ca/drain-cleaning-inspection-commercial"
                    to="/drain-cleaning-inspection-commercial"
                  >
                    Drain Cleaning
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.goproplumbing.ca/commercial-plumbing-troubleshooting"
                    to="/commercial-plumbing-troubleshooting"
                  >
                    Troubleshooting
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.goproplumbing.ca/watermain-replacement-commercial"
                    to="/watermain-replacement-commercial"
                  >
                    Watermain Replacement
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.goproplumbing.ca/kitec-removal-commercial"
                    to="/kitec-removal-commercial"
                  >
                    Kitec Removal
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.goproplumbing.ca/riser-replacement-commercial"
                    to="/riser-replacement-commercial"
                  >
                    Riser Replacement
                  </Link>
                </li>
              </ul>
            </Grid>

            <Grid
              item
              xs={10}
              sm={12}
              md={2}
              lg={2}
              style={{ paddingBottom: "20px" }}
            >
              <h4 className={classes.header}>LEAK DETECTION</h4>
              <ul>
                <li>
                  <Grid item xs={12} sm={12} md={2} lg={10}>
                    <Link
                      to="/leak-detection-phyn"
                      href="https://www.goproplumbing.ca/leak-detection-phyn"
                    >
                      Residential & Commercial Leak Detection
                    </Link>
                  </Grid>
                </li>
              </ul>
            </Grid>
            <Grid item xs={10} sm={12} md={2} lg={2}>
              <h4 className={classes.header}>EMERGENCY</h4>
              <ul className={classes.linksVertical}>
                <li>
                  <Link
                    to="/emergency-plumber"
                    href="https://www.goproplumbing.ca/emergency-plumber"
                  >
                    Emergency Plumber
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid item xs={10} sm={12} md={2} lg={2}>
              <h4 className={classes.header}>LOCATIONS</h4>
              <ul className={classes.linksVertical}>
                <li>
                  <Link to="/" href="https://goproplumbnig.ca/">
                    Toronto
                  </Link>
                </li>
                <li>
                  <Link
                    to="/plumber-oakville"
                    href="https://www.goproplumbing.ca/plumber-oakville"
                  >
                    Oakville
                  </Link>
                </li>
                <li>
                  <Link
                    to="/plumber-mississauga"
                    href="https://www.goproplumbing.ca/plumber-mississauga"
                  >
                    Mississauga
                  </Link>
                </li>
                <li>
                  <Link
                    to="/plumber-brampton"
                    href="https://www.goproplumbing.ca/plumber-brampton"
                  >
                    Brampton
                  </Link>
                </li>
              </ul>
            </Grid>
          </Grid>
        </div>
        <Grid
          container
          direction="row"
          style={{ paddingTop: "20px" }}
          justify="center"
          alignItems="center"
        >
          <Grid
            item
            style={{ paddingTop: "10px", paddingBottom: "30px" }}
            lg={4}
            xs={12}
            sm={12}
            md={10}
          >
            <div style={{ textAlign: "center", color: "white" }}>
              Copyright &copy; {1900 + new Date().getYear()}{" "}
              <a
                href="https://goproplumbing.ca"
                target="_blank"
                className={classes.footerLink}
              >
                | GoPro Plumbing |
              </a>{" "}
              All Rights Reserved.
            </div>
          </Grid>
          <Grid
            item
            style={{ position: "relative", top: "-10px" }}
            lg={3}
            xs={9}
            sm={12}
            md={10}
          >
            <a
              href="https://www.instagram.com/goproplumbing_"
              className={classes.linkStyle}
              title="instagram"
              id="instagram-link"
            >
              <Instagram
                onMouseEnter={() => setShowInstagram(true)}
                onMouseLeave={() => setShowInstagram(false)}
                fill={showInstagram ? "#0099CC" : "#fff"}
                stroke="white"
                height="25px"
                width="25px"
                id="instagram"
                title="Instagram"
                aria-labelledby="instagram-title"
                aria-describedby="instagram-desc"
                role="link"
                tabIndex="0"
              >
                <title id="instagram-title">Our Instagram page</title>
                <desc id="instagram-desc">Our Instagram Link</desc>
              </Instagram>
            </a>
            <a
              href="https://trustedpros.ca/company/gopro-plumbing"
              className={classes.linkStyle}
              title="TrustedPros"
              id="trustedPros-link"
            >
              <Trusted
                onMouseEnter={() => setPro(true)}
                onMouseLeave={() => setPro(false)}
                fill={showPro ? "#0099CC" : "#fff"}
                id="trustedPro"
                height="25px"
                width="25px"
                title="trustedPro"
                aria-labelledby="trustedPro-title"
                aria-describedby="trustedPro-desc"
                role="link"
                tabIndex="0"
              >
                <title id="trustedPro-title">Our Trusted Pros page</title>
                <desc id="trustedPro-desc">Our Trusted Pros Link</desc>
              </Trusted>
            </a>
            <a
              href="https://www.facebook.com/GoProPlumbingAndMechincalinc/"
              rel="noopener noreferrer"
              title="facebook"
              target="_blank"
              id="facebook-link"
              className={classes.linkStyle}
            >
              <Facebook
                onMouseEnter={() => setShowResults(true)}
                onMouseLeave={() => setShowResults(false)}
                fill={showResults ? "#0099CC" : "#fff"}
                stroke="white"
                height="30px"
                width="30px"
                id="facebook"
                title="Facebook"
                aria-labelledby="facebook-title"
                aria-describedby="facebook-desc"
                role="link"
                tabIndex="0"
              >
                <title id="facebook-title">Our Facebook page</title>
                <desc id="facebook-desc">Our Facebook Link</desc>
              </Facebook>
            </a>
            <a
              href="https://www.youtube.com/channel/UCxWqVttuxTbnKfB1KfrMLVQ"
              className={classes.linkStyle}
              title="youtube"
              id="youtube-link"
            >
              <Youtube
                onMouseEnter={() => setShowYoutube(true)}
                onMouseLeave={() => setShowYoutube(false)}
                fill={showYoutube ? "#0099CC" : "#fff"}
                stroke="white"
                id="youtube"
                height="30px"
                width="30px"
                title="Youtube"
                aria-labelledby="youtube-title"
                aria-describedby="youtube-desc"
                role="link"
                tabIndex="0"
              >
                <title id="youtube-title">Our Youtube page</title>
                <desc id="youtube-desc">Our Youtube Link</desc>
              </Youtube>
            </a>

            <a
              href="https://www.houzz.com/professionals/plumbers/gopro-plumbing-pfvwus-pf~62737434"
              className={classes.linkStyle}
              title="Houzz"
              id="houzz-link"
            >
              <Houzz
                onMouseEnter={() => setShowHouzz(true)}
                onMouseLeave={() => setShowHouzz(false)}
                fill={showHouzz ? "#0099CC" : "#fff"}
                id="houzz"
                height="23px"
                width="25px"
                title="Youtube"
                aria-labelledby="houzz-title"
                aria-describedby="houzz-desc"
                role="link"
                tabIndex="0"
              >
                <title id="houzz-title">Our Houzz page</title>
                <desc id="houzz-desc">Our Houzz Link</desc>
              </Houzz>
            </a>
          </Grid>
        </Grid>
        <Grid
          item
          style={{ position: "relative", top: "5px" }}
          xs={12}
          sm={12}
          md={10}
          lg={12}
        >
          <div style={{ textAlign: "center", color: "white" }}>
            Powered by {""}
            <a
              href="https://atlasagency.ca"
              target="_blank"
              className={classes.footerLink}
              rel="noreferrer"
            >
              Atlas Agency
            </a>
          </div>
        </Grid>
      </div>
    </footer>
  );
}
